<template>
  <div ref="canvansWarp"></div>
  <slot></slot>
</template>
<script>
// import { ajax } from '@/api';
// import PDFJS from 'pdfjs-dist'
let PDFJS = null;

const canvasArrays = [null];
export default {
  props: {
    src: {
      type: [String, Array, Object],
    },
    page: {
      type: [Number],
    },
  },
  data() {
    return {
      pdfDoc: null, // pdfjs 生成的对象
      pageNum: 1, //
      pageRendering: false,
      pageNumPending: null,
      scale: 1, // 放大倍数

      page_num: 0, // 当前页数

      page_count: 0, // 总页数
      ctx: [null],
      maxScale: 3,
      minScale: 0.2,
      bigOrsmall: false,
      loading: true,
    };
  },

  watch: {
    src: {
      handler() {
        if (this.src) {
          this.loading = false; // 这个是我为了判断图片是否加载成功，所添的参数
          if (window.pdfjsLib) {
            PDFJS = window.pdfjsLib;
            PDFJS.GlobalWorkerOptions.workerSrc = '/js/pdfjs/build/pdf.worker.js';
            this.getBase64(this.src);
          } else {
            this.createPDFJS(this.src);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    createPDFJS(url) {
      setTimeout(() => {
        PDFJS = window.pdfjsLib;
        PDFJS.GlobalWorkerOptions.workerSrc = '/js/pdfjs/build/pdf.worker.js';
        this.getBase64(url);
      }, 1000);
    },
    async getBase64(url) {
      // 这里是我用来请求后台返回给我返回base64格式的文件发的ajax请求
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      PDFJS.getDocument(url).then(function (pdfDoc_) {
        // 初始化pdf
        that.pdfDoc = pdfDoc_;

        that.renderPage(that.page);
      });
    },
    renderPage(num) {
      console.log('第' + num + '页');
      // 渲染pdf
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      const canvas = document.createElement('canvas'); // Using promise to fetch the page
      const div = document.createElement('div'); // Using promise to fetch the page
      div.classList.add('pdf-canvas');
      div.appendChild(canvas);
      const warp = this.$refs.canvansWarp;
      warp.appendChild(div);
      canvasArrays.push(canvas); // 存储每个画布
      canvas.getContext('2d');

      vm.pdfDoc.getPage(num).then(function (page) {
        console.log(page);
        vm.scale = document.querySelectorAll('.pdf-wrapper')[0].clientWidth / page.getViewport(1).width; // vm.wid是在data中定义的一个变量，最初设置的pdf的宽度

        const viewport = page.getViewport(vm.scale);
        console.log(viewport);
        canvas.height = viewport.height;
        canvas.width = viewport.width; // Render PDF page into canvas context
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport,
        };
        const renderTask = page.render(renderContext); // Wait for rendering to finish
        renderTask.promise.then(() => {
          console.log('success');
          // vm.pageRendering = true;
          // vm.pageNum = vm.pageNum + 1;
          // if (vm.pageNum <= vm.page_count) {
          //   vm.renderPage(vm.pageNum);
          // } else {
          //   vm.pageRendering = true;
          // }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#canvansWarp {
  background: #f7f7f7;
  overflow: auto;
  padding-top: 44px;
  background: #404040;
  text-align: center;
  position: absolute;
  width: 100%;
}
#canvansWarp canvas {
  /* margin-bottom: 10px; */
  margin: 0 auto;
}

.pdf-bar {
  z-index: 9999999;
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  background: #474747;
  height: 32px;
  display: flex;
}
.zoom-btn {
  width: 32px;
  height: 32px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 32px;
}
</style>
